<template>
  <div>
    <br />
    <br />
    <div class="row align-items-end">
      <div class="col-xl-3 col-12">
        <label for="">Catégorie</label>
        <select v-model="section_reference" class="form-select">
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-12">
        <label for="">Produit</label>
        <select v-model="order_item.item_reference" class="form-select">
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>

      <div class="col-xl col-12">
        <label for="">Prix U (TTC*)</label>
        <input type="text" v-model="order_item.priceTTC" class="form-control" />
      </div>

      <div class="col-xl col-12">
        <label for="">Quantité </label>
        <input type="text" v-model="order_item.quantity" class="form-control" />
      </div>

      <div class="col-xl-auto col">
        <label for=""></label>
        <button class="btn btn-success" @click="save(order_item)">
          <i class="bi bi-plus-circle"></i> Insérer
        </button>
      </div>

      <div class="col-xl-auto col-auto">
        <button
          class="btn btn-secondary d-flex align-items-center"
          @click="refresh()"
        >
          <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
          <span> Actualiser </span>
        </button>
      </div>
    </div>
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix (DH*)</th>
          <th scope="col">Quantité</th>
          <th scope="col">Montant Total (DH*)</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in order_items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td class="text-info">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>
          <td class="text-warning">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
          </td>
          <td class="text-info">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>

          <td>
            <span v-if="item.user">
              {{ item.user.name }}
            </span>
          </td>
          <td class="d-flex">
            <button
              @click="
                $router.push({
                  name: 'items-details',
                  params: { reference: item.reference },
                })
              "
              class="btn text-danger"
            >
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>
          <td>
            <span
              v-if="totalQuantityItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ parseFloat(totalQuantityItems).toFixed(3) }}
            </span>
          </td>
          <td>
            <span
              v-if="totalPriceTTCItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ parseFloat(totalPriceTTCItems).toFixed(2) }}
            </span>
            DH
          </td>

          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-xl-8 col-12">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat ( Quantité totale
            : <strong>{{ totalQuantityItems }}</strong> Kg, Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-xl-auto col-12">
        <button
          class="btn btn-success"
          @click="validated()"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      order_item: {},
      section_reference: "",
      accept: false,
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),

    ...mapGetters("order", {
      order_items: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("order/storeItem", data);
      await (this.order_item = {});
    },
    async empty() {},
    async refresh() {
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("order/getAllItems");
    },
    async validated() {
      this.$router.push({ name: "orders-create" });
    },
  },
  beforeMount() {
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
    this.$store.dispatch("order/getAllItems");
  },
};
</script>
<style scoped>

</style>